// src/pages/Dashboard.js
// src/pages/Dashboard.js
// src/pages/Dashboard.js
import React from 'react';
import './Dashboard.css';

function Dashboard() {
  return (
    <div className="dashboard-container">
      <h1 className="dashboard-title">Willkommen im Dashboard</h1>
      <div className="dashboard-widgets">
        <div className="widget">
          <h2>Kontostand</h2>
          <p>€ 1.234,56</p>
        </div>
        <div className="widget">
          <h2>Letzte Transaktionen</h2>
          <p>2 neue Transaktionen</p>
        </div>
        <div className="widget">
          <h2>Schneller Zugriff</h2>
          <button>Anmeldung</button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
