// src/services/api.js
import axios from 'axios';

const API_BASE_URL = 'http://localhost:3000'; // Ändere dies auf deine Produktions-URL, z.B. https://pay-ease.de

// Benutzer registrieren
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/register`, userData);
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

// Benutzer anmelden
export const loginUser = async (credentials) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/login`, credentials);
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

// Transaktionen abrufen
export const fetchTransactions = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/transactions/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Fetch transactions error:', error);
    throw error;
  }
};

// Neue Transaktion hinzufügen
export const addTransaction = async (transactionData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/transaction`, transactionData);
    return response.data;
  } catch (error) {
    console.error('Add transaction error:', error);
    throw error;
  }
};

// Funktion zum Abrufen des Benutzerprofils
export const fetchUserProfile = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/profile`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Token hinzufügen, falls erforderlich
      },
    });
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen des Benutzerprofils:', error);
    throw error;
  }
};

export const fetchRecentTransactions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/transactions/recent`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der letzten Transaktionen:', error);
    throw error;
  }
};