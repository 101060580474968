// src/pages/Register.js
// src/pages/Register.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../services/api';
import './Register.css';

function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await registerUser({ name, email, password });
      navigate('/login');
    } catch (err) {
      setError('Registrierung fehlgeschlagen. Bitte versuchen Sie es erneut.');
    }
  };

  return (
    <div className="register-container">
      <h1 className="register-title">Registrieren bei PayEase</h1>
      <form onSubmit={handleRegister} className="register-form">
        {error && <p className="error-message">{error}</p>}
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="register-input"
          placeholder="Vollständiger Name"
        />
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="register-input"
          placeholder="E-Mail"
        />
        <label>Passwort:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="register-input"
          placeholder="Passwort"
        />
        <button type="submit" className="register-button">Registrieren</button>
      </form>
      <p className="login-redirect">
        Bereits ein Konto? <span onClick={() => navigate('/login')}>Anmelden</span>
      </p>
    </div>
  );
}

export default Register;
